import React, { useEffect, useContext, useState, useRef } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../../config/app-settings.js';
import { Modal } from 'bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dishesServices from '../../../api/DishesServices/disheseService.js';
import taxServices from '../../../api/TaxServices/taxServices.js';
import { useLocation } from "react-router-dom"; 
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import printService from '../../../api/PrintServices/printService.js';
import PosHeader from '../../../components/topbar/posHeader.jsx';
import tableServices from '../../../api/TableServices/tableServices.js';
import tableCardPermissions from '../../../enums/tableCardPermissions.js';
import OpenItemModal from "./OpenItemModal.jsx";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { v4 as uuidv4 } from 'uuid';
import CenteredModal from "./CenteredModal.js";


function PosCustomerOrder() {
	const location = useLocation();
	const context = useContext(AppSettings);
	const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
	const [categoryType, setCategoryType] = useState('all');
	const [stall, setstall] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [orderData, setOrderData] = useState([]);
	const [transactionId, setTransactionId] = useState(0);
	const [cardData, setCardData] = useState([]);
	const [Taxes, setTaxes] = useState([]);
	const [managerCards, setManagerCards] = useState([]);
	const [cardIds, setCardIds] = useState([]);
	const [orderHistoryData, setOrderHistoryData] = useState([]);
	const [modal, setModal] = useState();
	const [cardNo, setcardNo] = useState('');
	const [rfidManagerDetails, setRfidManagerDetails] = useState([]);
	const [rfidManagerCardNo, setRfidManagerCardNo] = useState('');
	const [rfidCardNo, setRfidCardNo] = useState('');
	const [modalData, setModalData] = useState();
	const [modalQuantity, setModalQuantity] = useState();
	const [modalSelectedAddon, setModalSelectedAddon] = useState([]);
	const stallId = localStorage.getItem('stallID');
	const stallIdInt = parseInt(stallId, 10);
	const [Status, setStatus] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false); 
	const [voidReason, setVoidReason] = useState("");  
	const [managerUsername, setManagerUsername] = useState("");  
	const [managerPassword, setManagerPassword] = useState("");  
	const [isValid, setIsValid] = useState(false);    
	const [errorMessage, setErrorMessage] = useState("");  
	const [isVoidData, setIsVoidData] = useState(false);
	const [isEmptyVoidData, setIsEmptyVoidData] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [showModal, setShowModal] = useState(false); 
	const [voidReasonData, setVoidReasonData] = useState([]);
	const [mangerTrue, setMangerTrue] = useState(false);
  
	const handleOpenModals = () => setShowModal(true); 
	const handleCloseModals = () => setShowModal(false); 
	const [selectedTableId, setSelectedTableId] = useState('');
  	const [TableIds, setLocalTableId] = useState("NO");
	const [TableStatus, setTableStatus] = useState("FALSE");
	const [GetTables, setTables] = useState([]);
	const [cardValidation, setCardValidation] = useState([]);
	const [stallStatus, setStallStatus] = useState('');
	const [remainingCount, setRemainingCount] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isOrderSubmitting, setIsOrderSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [modalShow, setModalShow] = React.useState(false);
	const [clickedIndex, setClickedIndex] = useState(null);
	const previousCardNo = useRef(cardNo);
	const [kCCID, setKCCID] = useState();
	const [openItems, setOpenItems] = useState([]);
	const handleModalOpen = () => setIsModalOpen(true);
	const handleModalClose = () => setIsModalOpen(false);
	const WebSocketUrl = process.env.REACT_WEBSOCKET_URL;
    const modalInstanceRef = useRef(null);

	const [showOrdersModal, setShowOrdersModal] = useState(false);
    const [cardInProgressOrders, setCardInProgressOrders] = useState([]);

	const dropdownRef = useRef(null);
	
		const handleMoveUp = () => {
		  if (dropdownRef.current) {
			const options = dropdownRef.current.options;
			const selectedIndex = dropdownRef.current.selectedIndex;
	  
			if (selectedIndex > 0) {
			  const newIndex = selectedIndex - 1;
			  setVoidReason(options[newIndex].value);
			}
		  }
		};
	  
		const handleMoveDown = () => {
		  if (dropdownRef.current) {
			const options = dropdownRef.current.options;
			const selectedIndex = dropdownRef.current.selectedIndex;
	  
			if (selectedIndex < options.length - 1) {
			  const newIndex = selectedIndex + 1;
			  setVoidReason(options[newIndex].value);
			}
		  }
		};

    const openOrdersModal = () => {
		const modalElement = document.getElementById('inProgressOrdersModal');
		if (modalElement && !modalInstanceRef.current) {
	
			const existingBackdrop = document.querySelector('.modal-backdrop');
			if (existingBackdrop) {
				existingBackdrop.remove();
			}
	
			modalInstanceRef.current = new Modal(modalElement, {
				backdrop: 'static',
				keyboard: false
			});
	
			modalElement.addEventListener('hidden.bs.modal', () => {
				if (modalInstanceRef.current) {
					modalInstanceRef.current.dispose();
					modalInstanceRef.current = null;
				}
				setShowOrdersModal(false);
			}, { once: true });
	
			modalInstanceRef.current.show();
		}
	};
	
	const closeOrdersModal = () => {
		if (modalInstanceRef.current) {
			modalInstanceRef.current.hide();
		}
	};


	const handleOrdersClick = async () => {
		if (!cardNo) return;
		try{

		let transactions = await getAllTransactions();

		const inProgressOrders = transactions.filter(transaction => 
			transaction.cardId === cardNo && 
			transaction.statusChange === "INPROGRESS"
		);
		setCardInProgressOrders(inProgressOrders);
		setShowOrdersModal(true);
		openOrdersModal();
	
	}
	catch(error)
	{console.error("Error fetching transactions:", error);
	}
	};

	const navigate = useNavigate();
	const redirectToCheckout = () => {
		navigate('/pos/counter-checkout-stall');
	  };

	  const handleClick = (event) => {
		submitOrder(event);   
		redirectToCheckout();  
	  };

	var SubTotal = 0;
	var TotalTaxesPrice = 0;
	var TotalPrice = 0;
	var TaxesPrice = 0;

	const voidData = JSON.parse(localStorage.getItem("voidbill") || "{}");

	useEffect(() => {
		if (selectedTableId) {
		setTableStatus("BOOKED");
		} else {
		setTableStatus("FREE"); 
		}
  	}, [selectedTableId]); 

   useEffect(() => {
     if (modalShow === true) {
       setModalShow(false);
     }
   }, [cardNo]); 

	let isVoidBillData = (location.state?.fromOngoingOrders)
 
	const managerCredentials = {
    username: "admin",
    password: "123"
    };

	useEffect(() => {
		  
		if (Object.keys(voidData).length === 0) {
			setIsVoidData(false);
			setOrderData([]);
        	setCardData([]);
        	setTransactionId(0);
			setModalShow(true);
		}
	 
	}, [JSON.stringify(voidData)]);

	useEffect(() => {

    const fetchVoidReasonsAndData = async () => {
      if (voidData && isVoidBillData) { 
        setIsVoidData(true);
        setOrderData([]);
        setCardData([]);
        setTransactionId(0);
 
        voidData?.transactionItems?.forEach((item) => {
          setOrderData((prevOrderData) => [
            ...prevOrderData,
            {
              orderItemId: 0,
              transactionID: item?.transactionID,
              menuItemId: item?.menuItemId,
              itemName: item?.itemName,
              quantity: item?.quantity,
              itemPrice: item?.itemPrice,
              taxItems: item?.taxItems,
              addOns: item?.addOns,
              createdByScreenID: item?.createdByScreenID,
              addOnTotal: 0,
              id: uuidv4(),
            },
          ]);
        });

 		setTotal(voidData?.totalAmount);
        setCardData(voidData);
        setTransactionId(voidData?.transactionID);
 
        fetchVoidReasons();
      }
    };
 
    fetchVoidReasonsAndData();
  }, [location.state]);

  const fetchVoidReasons = async () => {
	try {
	  const voidReasons = await dishesServices().getVoidReasons();
	  if (Array.isArray(voidReasons)) {
		setVoidReasonData(voidReasons);
	  } else {
		console.error("voidReasons is not an array");
	  }
	} catch (error) {
	  console.error("An error occurred while fetching void reasons:", error);
	}
  };

	useEffect(() => {
		return () => {
		if (modalInstanceRef?.current) {
			modalInstanceRef?.current.dispose();
		}
		};
	}, []);

	useEffect(() => {
		let calculatedTotalPrice = 0;

		if (orderData) {
			orderData.forEach((order) => {
			const itemPrice = parseFloat(order.itemPrice) || 0;
			const addOnTotal = parseFloat(order.addOnTotal) || 0;
			const quantity = parseInt(order.quantity) || 0;

			calculatedTotalPrice += (itemPrice + addOnTotal) * quantity;
			});
			calculatedTotalPrice += parseFloat(TotalTaxesPrice) || 0;
		}
 
		setTotalPrice(calculatedTotalPrice);
 
		if (calculatedTotalPrice > 0) {
			setIsEmptyVoidData(false);  
		} else {
			setIsEmptyVoidData(true);  
		}

	}, [orderData, TotalTaxesPrice]);  

	useEffect(() => {

    const fetchStallStatus = async () => {

      const status = await checkStallStatus();
      setStallStatus(status);

    };

    fetchStallStatus();

  }, []);

	var toggleMobileSidebar = (event) => {
		event.preventDefault();
		
		setPosMobileSidebarToggled(true);
	}
	
	var dismissMobileSidebar = (event) => {
		event.preventDefault();
		
		setPosMobileSidebarToggled(false);
	}
	
	const showType = (event, subCategory) => {
		event.preventDefault();
		
		if (tableData) {
			const updatedTableData = tableData.map(item => ({
				...item,
				hide: subCategory !== 'all' && item.subCategory !== subCategory
			}));
			 
			setTableData(updatedTableData);
			setCategoryType(subCategory);
		}
	}
	
	var showPosItemModal = (event, data) => {
		var validationTotal=parseFloat((voidData && isVoidData) ? ((total != 0) ? total : (totalPrice) ) : (totalPrice));
		var taxTotal = 0
		event.preventDefault();
		var taxes = [];
		var totalTaxInd = 0;
	
	
		if (data && Taxes) {

			if (Taxes) {
				Taxes?.forEach((tax) => {
					const taxPercentage = parseFloat(tax.taxRate) || 0;
					const price = parseFloat(data.price) || 0;
		
					const option1 = {
						"taxItemId": 0,
						"orderItemId": 0,
						"acumaticaTaxId": 0,
						"taxCode": tax.taxCode,
						"taxType": tax.taxType,
						"taxPercentage": taxPercentage,
						"taxValue": (price / 100) * taxPercentage
					};
		
					totalTaxInd += (price / 100) * taxPercentage;
					taxes.push(option1);
					taxTotal += totalTaxInd;
				});
			}
			
			if (data.menuItemAddOns.length === 0) {
				setTotal(0);
				if(validationTotal+(data.price+taxTotal)>remainingCount){
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: `The maximum allowed amount for this card exceeds.`,
						confirmButtonColor: '#d33',
						allowOutsideClick: false,
					});
					return;
				}
				setOrderData((prevOrderData) => {
					const dataExists = prevOrderData.some(
						(obj) => obj.menuItemId === data.menuItemId
					);
						
	
						if  (dataExists) {
							addaQty(event, data.menuItemId);
							return prevOrderData;
						} else {
							return [
								...prevOrderData,
								{
									orderItemId: 0,
									transactionID: 0,
									menuItemId: data.menuItemId,
									itemName: data.title,
									quantity: 1,
									taxItems: taxes,
									itemPrice: data.price,
									addOns: [],
									addOnTotal: 0,
									createdByScreenID: "NEED-CLARIFICATION-FROM-ACU",
									id: uuidv4(),
								},
							];
						}
					});
			
			} else {
				setModalData(data);
				setModalQuantity(1);
				setModalSelectedAddon([]);
				modal.show();
			}
		}
	};
	
	
	var getOrderTotal = () => {
		return (orderData) ? orderData.length : 0;
	}
	
	var getOrderHistoryTotal = () => {
		return (orderHistoryData) ? orderHistoryData.length : 0;
	}
	
	var deductQty = (event, id) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.map(obj => {
				if (obj.id === id) {
					var newQty = parseInt(obj.quantity) - 1;
					
					if (newQty < 1) {
						newQty = 1;
					}
					return {...obj, quantity: newQty};
				}
				
				return obj;
			});

			setTotal(0);
			setOrderData(newData);
		}
	}
	
	var addQty = (event, id) => {
		event.preventDefault();
		var validationTotal=parseFloat((voidData && isVoidData) ? ((total != 0) ? total : (totalPrice) ) : (totalPrice));
		var totaltaxes = 0
		var totaladdOns = 0
	
		if (orderData) {
			const newData = orderData.map(obj => {
				if(obj.taxItems){
					obj.taxItems?.forEach((tax) => {
						
						totaltaxes += tax.taxValue;
					});
				}
				if(obj.addOns){
					obj.addOns?.forEach((addon) => {
						
						totaladdOns += addon.price;
					});
				}

				if (obj.id === id) {
					var newQty = parseInt(obj.quantity) + 1;
	
					if ((validationTotal+obj.itemPrice+totaltaxes+totaladdOns) > remainingCount) {
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: `The maximum amount allowed for this card has exceed.`,
							confirmButtonColor: '#d33',
							allowOutsideClick: false,
						});
						return obj;
					}
	
					return { ...obj, quantity: newQty };
				}
	
				return obj;
			});
	
			setTotal(0);
			setOrderData(newData);
		}
	};
	

	const handleQtyChange = (event, orderId) => {
		const newQuantity = event.target.value;
		var validationTotal=parseFloat((voidData && isVoidData) ? ((total != 0) ? total : (totalPrice) ) : (totalPrice));
		var totaltaxes = 0;
		var totaladdOns = 0;
		
		if (/^\d*$/.test(newQuantity)) {
			setTotal(0);

			setOrderData((prevOrders) =>
				prevOrders.map((order) => {

					if (order.id === orderId) {
						const validatedQuantity = Math.max(1, Math.min(newQuantity, order.maxQuantity || Infinity));

						if ((validationTotal) > remainingCount) {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								text: `The maximum amount allowed for this card has exceed.`,
								confirmButtonColor: '#d33',
								allowOutsideClick: false,
							});
						}
	
						return { ...order, quantity: validatedQuantity };
					}
	
					return order;
				})
			);
		}
	};
	

	  var addaQty = (event, menuItemId) => {
		event.preventDefault();
		var validationTotal=parseFloat((voidData && isVoidData) ? ((total != 0) ? total : (totalPrice) ) : (totalPrice));
		var totaltaxes = 0
		var totaladdOns = 0
	
		if (orderData) {
			const newData = orderData.map(obj => {
				if(obj.taxItems){
					obj.taxItems?.forEach((tax) => {
						
						totaltaxes += tax.taxValue;
					});
				}
				if(obj.addOns){
					obj.addOns?.forEach((addon) => {
						
						totaladdOns += addon.price;
					});
				}
				if (obj.menuItemId === menuItemId) {
					var newQty = parseInt(obj.quantity) + 1;

					if ((validationTotal+obj.itemPrice+totaltaxes+totaladdOns) > remainingCount) {
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: `The maximum amount allowed for this card has exceed.`,
							confirmButtonColor: '#d33',
							allowOutsideClick: false,
						});
						return obj;
					}
	
					return { ...obj, quantity: newQty };
				}
	
				return obj;
			});
	
			setTotal(0);
			setOrderData(newData);
		}
	};
	

	var getSubTotalPrice = () => {
		let subTotal = 0;
	
		if (orderData) {
			orderData?.forEach(order => {
				const itemPrice = parseFloat(order.itemPrice) || 0;
				const addOnTotal = parseFloat(order.addOnTotal) || 0;
				const quantity = parseInt(order.quantity) || 0;
	
				subTotal += (itemPrice + addOnTotal) * quantity;
			});
			SubTotal += subTotal;
		}
		
		// return subTotal.toFixed(2);
	};
	
	var getTaxesPrice = (taxType) => {
		var TaxesPrice = 0;
		 
		orderData?.forEach( (order) => {
			
			order?.taxItems?.forEach( (tax) => {
			if(tax.taxType == taxType){
				TaxesPrice += (tax.taxValue)*order.quantity
			}
			});
		});
		TotalTaxesPrice += TaxesPrice;
		
		// return TaxesPrice.toFixed(2);
	}
	
	var toggleConfirmation = (event, id, value) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.map(obj => {
				if (obj.id === id) {
					return {...obj, confirmation: value};
				}
				return obj;
			});

			setTotal(0);
			setOrderData(newData);
		}
	}
	
	var removeOrder = (event, id) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.filter(function(order) { 
				return order.id !== id
			});
			setTotal(0);
			setOrderData(newData);
 
		}
	}
	
	var addModalQty = (event) => {
		event.preventDefault();
		if (modalQuantity) {
			var newQty = parseInt(modalQuantity) + 1;
			
			setModalQuantity(newQty);
		}
	}

	var getTotalDishPrice = (order) => {
		let totalPrice = 0;
	
		if (stall) {
			totalPrice = (
				order.itemPrice + 
				order.addOnTotal + 
				order.taxItems.reduce((acc, tax) => acc + parseFloat(tax.taxValue || 0), 0)) * order.quantity;
		} else {
			totalPrice = (order.itemPrice + order.addOnTotal) * order.quantity;
		}
	
		return totalPrice.toFixed(2);
	};

	var deductModalQty = (event) => {
		event.preventDefault();
		
		if (modalQuantity) {
			var newQty = parseInt(modalQuantity) - 1;
		
			if (newQty < 1) {
				newQty = 1;
			}
			setModalQuantity(newQty);
		}
	}

	var handleAddonChange = (event) => {
		var elms = [].slice.call(document.querySelectorAll('input[name="addon"]'));
		var targetValue = [];
		
		elms.map(function(elm) {
			if (elm.checked) {
				targetValue.push({
					value: elm.value,
					price: elm.getAttribute('data-price')
				});
			}
			return true;
		});
		
		setModalSelectedAddon(targetValue);
	}

	const openModal = () => {
    const modalElement = document.getElementById("modalVoidItem");
    modalInstanceRef.current = new Modal(modalElement);
    modalInstanceRef.current.show();
  };

	const closeModal = () => {
    if (modalInstanceRef.current) {
      modalInstanceRef.current.hide();
    }
 
    setIsValid(false);
    setVoidReason("");
    setManagerUsername("");
    setManagerPassword("");
    setErrorMessage("");
  };

	const handleValidation = () => {
		if (
		managerUsername === managerCredentials.username &&
		managerPassword === managerCredentials.password
		) {
		setIsValid(true);
		setErrorMessage("");
		} else {
		setErrorMessage("Invalid username or password.");
		}
	};

	const handleSubmitOrder = async () => { 
		if(!rfidManagerDetails?.userName){
		const validationResult = await dishesServices().validManager(managerUsername, managerPassword);

		if (!validationResult.success) {  
			setErrorMessage(validationResult.message);
			return;  
		}
	}
		setErrorMessage("");

		try { 
			await sendVoidData();
		} catch (error) { 
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'order failed. Please try again.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			})
		}
	};
 
	const openCancelOrderModal = () => {
		const modalElement = document.getElementById("cancelOrderModal");
		modalInstanceRef.current = new Modal(modalElement);
		modalInstanceRef.current.show();
	};

	const handleCancelOrder = async () => {
		if(!rfidManagerDetails?.userName){
			const validationResult = await dishesServices().validManager(managerUsername, managerPassword);

		if (!validationResult.success) { 
			setErrorMessage(validationResult.message);
			return;  
		}
	}
	
	
		setErrorMessage(""); 
		await sendCancelData();
			setRfidManagerDetails([]);
			setMangerTrue(false);
			setRfidCardNo('');
	};

	const submitOrder = (event) => {
		event.preventDefault();
		setIsSubmitting(true);
		setIsButtonDisabled(true);
	
		const phoneNumber = Cookies.get('phoneNumber');
		sendData();

		if (stallStatus === tableCardPermissions.TableYesCardNo) {
			setTimeout(() => {
				window.location.reload();
			}, 1000); //temporary solution
		}
		if (orderData.length > 0) {
			setIsSubmitting(false);
		}

		setTimeout(() => {
			setIsButtonDisabled(false);
		}, 1000);
	};

	const sendCancelData = async () => { 
		try { 
   
		await dishesServices().updateTransactionStatusToVoid(
			transactionId,
			voidReason,
			managerUsername
		);
 
		await printService().handleGateControlCardWithVoid(cardData?.cardId).then(async (response) =>{
			
			if (response.data === true) {
				try {
						await printService().handleGateControlCard("Active", [cardData?.cardId]);
					} catch (error) {
						console.error("Failed to update Gate Card Status", error);
					}
				}
			}).catch((error) => { 
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Failed to Update Gate Card Status. Please try again.',
					confirmButtonColor: '#d33',
					allowOutsideClick: false,
				})
				console.error("Error submitting order:", error);
			});
		
		setIsVoidData(false);
		setOrderData([]);
		setCardData([]);
		setTransactionId(0);
		setModalSelectedAddon([]);

		//await closeModal();
 
		if (voidData) {
			localStorage.removeItem("voidbill");
		}
 
		SubTotal = 0;
		TotalTaxesPrice = 0;
		TaxesPrice = 0;
			
		setTotalPrice(0);
		setSelectedTableId(0);
		setLocalTableId("NO"); 
		setcardNo("");

		navigate(`/pos/void-orders`);
		 
		//toast.success("Order was successful!");
		} catch (error) {
		Swal.fire({
			icon: 'error',
			title: 'Error',
			text: 'Order failed. Please try again.',
			confirmButtonColor: '#d33',
			allowOutsideClick: false,			
		})
		}
	};

	const sendVoidData = async () => {
	 
		if (!orderData || orderData.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order data is empty. Please add items to your order.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			})
			return;
		}
		
		try { 
 
			const responset = await dishesServices().updateTransactionStatusToVoid(
				orderData[0].transactionID,
				voidReason,
				managerUsername
			);
			await dishesServices().postVoidData(
				orderData,
				totalPrice,
				TotalTaxesPrice, 
				SubTotal,
				orderData[0].transactionID,
				cardNo,
				Status,
				TableIds,
				TableStatus,
				stallIdInt,
				cardData
				).then(async (response) => { 

					setTimeout(() => {
						setIsOrderSubmitting(false);
					}, 2000);

					// if (response) {
					// 	debugger
					// 	try { 
					// 		 printService().sendVoidKOTBill(response?.data, orderData); 
					// 	} catch (error) {
					// 		console.error("Failed to print Void KOT Bill:", error);
					// 	}
					// }
					
				}).catch((error) => { 
					setIsOrderSubmitting(false);
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: 'Failed to submit order. Please try again.',
						confirmButtonColor: '#d33',
						allowOutsideClick: false,
					})
					console.error("Error submitting order:", error);
				});
 
			setIsVoidData(false);
			setOrderData([]);
			setCardData([]);
			setTransactionId(0);
			setModalSelectedAddon([]);

			if (voidData) {
				localStorage.removeItem("voidbill");
			}

			SubTotal = 0;
			TotalTaxesPrice = 0;
			TaxesPrice = 0;

			setTotalPrice(0);
			setSelectedTableId(0);
			setLocalTableId("NO"); 
			setcardNo("");
			navigate(`/pos/void-orders`);
			
			//toast.success("Order was successful!");
		} catch (error) {
			setIsOrderSubmitting(false);
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order failed. Please try again.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			})
		}
	};

	const sendData = async () => {
		setIsSubmitting(true);
		
		if (!orderData || orderData.length === 0) {
			setIsOrderSubmitting(false);
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order data is empty. Please add items to your order.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			})
			setIsSubmitting(false);
			return;
		}

		if (cardNo) {
			var foundCard = cardValidation?.find(card => card.card_Id === cardNo) || null;
			// console.log('Found Card:', foundCard);
			if (foundCard) {
				setKCCID(foundCard.kcc_Id)
				// console.log('KCC ID:', foundCard.kcc_Id);
			}}
		
		if(stallStatus === tableCardPermissions.CardOnly){
			if (cardNo === 0 || cardNo === '') {
				setIsOrderSubmitting(false);
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'The maximum amount allowed for this card has exceed.',
					confirmButtonColor: '#d33',
					allowOutsideClick: false,
				})
				setIsSubmitting(false);
				return;
			}
		}
		try {
			// let selectedCard = null;
			// const targetCardId = Number(cardNo);
		    // const matchingTransactions = GetAllTransaction.filter(transaction =>transaction.cardId === targetCardId && transaction.statusChange === "INPROGRESS");
			// const totalAmountSum = matchingTransactions.reduce((sum, transaction) => sum + transaction.totalAmount, 0)
			// for (let i = 0; i < cardIds.length; i++) {
			//   if (cardIds[i][0] === targetCardId) {
			// 	selectedCard = cardIds[i]; 
			// 	break;
			//   }
			// }
			// if (selectedCard) {
			//   const selectedCardMaxAmount = selectedCard[1]; 
			// var total =selectedCardMaxAmount-totalAmountSum
			//   if (totalPrice+totalAmountSum > selectedCardMaxAmount) {
			// 	Swal.fire({
			// 		icon: 'error',
			// 		title: 'Error',
			// 		text: `Total price exceeds the maximum allowed amount for this card. Remaining balance is ${total}.`,
			// 		confirmButtonColor: '#d33',
			// 		allowOutsideClick: false,
			// 	})
			// 	return;
			//   }
			// }
			if (orderData) {
				const hasInvalidTaxItems = orderData.some((SelectedOrderData) => SelectedOrderData.taxItems.length === 0);
				
				if (hasInvalidTaxItems) {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: 'Order failed. Please <span style="color: red; font-weight: bold;">Refresh the page</span> and try again.',
						confirmButtonColor: '#d33',
						allowOutsideClick: false,
					});
					setOrderData([]);
					setcardNo('');
					setRfidCardNo('');
					setRemainingCount(0);
					setIsOrderSubmitting(false);
					setIsSubmitting(false);
				}
			}
		if(stallStatus === tableCardPermissions.CardOnly)
			{
				const response_1 = await printService().handleSaveCard("Inprocess",[cardNo]);
				// const response_2 = await printService().handleGateControlCard("Inprocess",[cardNo]);
			}

		const response = await dishesServices().postData(orderData, totalPrice, TotalTaxesPrice, SubTotal,cardNo,foundCard.kcc_Id,Status,TableIds,TableStatus,stallIdInt);

		setOrderData([]);
		setModalSelectedAddon([]);
		setSelectedTableId(0);
		setLocalTableId("NO");
		setTotalPrice(0)
		setIsSubmitting(false);
		
		SubTotal = 0;
		TaxesPrice = 0;
		
		setcardNo('');
		setRfidCardNo('')
		setRemainingCount(0);
		setIsSubmitting(false);
		setTimeout(() => {
			setIsOrderSubmitting(false);
			setTimeout(() => {
				setModalShow(true);
			}, 0);
		}, 2000);

		if (response) {
			try {
				await printService().sendKOTBill(response);
			} catch (error) {
						
				console.error("Failed to print KOT bill:", error);
			}
		}
		
		} catch (error) {
			setIsOrderSubmitting(false);
			if(stallStatus === tableCardPermissions.CardOnly){
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: 'Order failed. Please <span style="color: red; font-weight: bold;">Refresh the page</span> and try again.',
					confirmButtonColor: '#d33',
					allowOutsideClick: false,
				});

				setIsSubmitting(false);
			}
		}
	};

	var addToCartVoidData = (voidBillData) => {
		var addOns = [];
		var totalAddOn = 0;

		setTimeout(() => {
		setOrderData((prevOrderData) => [
			...prevOrderData,
			{
			orderItemId: 0,
			transactionID: 0,
			menuItemId: voidBillData?.menuItemId,
			itemName: voidBillData?.itemName,
			quantity: voidBillData?.quantity,
			itemPrice: voidBillData?.itemPrice,
			addOns: voidBillData?.addOns,
			addOnTotal: totalAddOn,
			createdByScreenID: voidBillData?.createdByScreenID,
			id: (prevOrderData?.length || 0) + 1,
			},
		]);
		}, 500);
	};
	
		var addToCart = (event) => {
			event.preventDefault(); 
			modal.hide();
			
			var addOns = [];
			var taxes = [];
			var totalAddOn = 0;
			var totalTaxInd = 0;
			
			if (modalSelectedAddon) {
				modalSelectedAddon?.forEach( (SelectedAddon) => {
					const price = parseFloat(SelectedAddon.price) || 0;
					var option = {
						"addOnID": 0,
						"orderItemId": 0,
						"addon": SelectedAddon.value,
						"price": price,
						"key": "addon",
					};
					totalAddOn += parseFloat(SelectedAddon.price) || 0;
					addOns.push(option);
				});
				
			}
	
			if (Taxes && modalData) {
				if (Taxes) {
			
				Taxes?.forEach((tax) => {
					const taxPercentage = parseFloat(tax.taxRate) || 0;
					const price = parseFloat(modalData.price) || 0;
					const addOn = parseFloat(totalAddOn) || 0;
	
			
					const option1 = {
						"taxItemId": 0,
						"orderItemId": 0,
						"acumaticaTaxId": 0,
						"taxCode": tax.taxCode,
						"taxType": tax.taxType,
						"taxPercentage": taxPercentage,
						"taxValue": ((addOn + price) / 100) * taxPercentage
					};
					totalTaxInd += ((addOn + price) / 100) * taxPercentage;
					taxes.push(option1)
				});
			}
			setTimeout(() => {
				setTotal(0);
				setOrderData([...orderData, {
					"orderItemId": 0,
					"transactionID": 0,
					"menuItemId": modalData?.menuItemId,
					"itemName": modalData?.title,
					"quantity": modalQuantity,
					"taxItems": taxes,
					"itemPrice": modalData?.price,
					"addOns": addOns,
					"addOnTotal":totalAddOn,
					"createdByScreenID":"NEED-CLARIFICATION-FROM-ACU",
					"id": (orderData?.length || 0) + 1,
				}]);
			}, 500);
		}

		}

		const fetchDishes = async () => {
			if (!stallId) {
				return;
			}
		
			setIsLoading(true);
		
			try {
				const response = await dishesServices().getMenuItemsByStall(stallId);
				response.sort((a, b) => a.title.localeCompare(b.title));
				setTableData(response);

			} catch (error) {
				console.error('Error fetching menu items:', error);
			} finally {
				setIsLoading(false);
			}
		};
		

		const fetchTaxes = async () => {
		try {
		  const response = await taxServices().getAllTax();
	
		  setTaxes(response.data); 
		  
		} catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	  };
	  const  fetchManagerCards = async () => {
		try {
		  const response = await dishesServices().getAllManagerCards();
	
		  setManagerCards(response.data);
		}
		catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	  };


		const fetchTables = async () => {
			try {
				const response = await dishesServices().getAllTables();
				setTables(response.data); 
				
			} catch (error) {
				console.error('Error fetching suppliers data:', error);
			}
			};

			const getTransactionsTotal = async (cardNo) => {
				try {
					
					const response = await dishesServices().getAllinprogressOders(cardNo);
					return response.data;

				} catch (error) {
					console.error('Error fetching transactions data:', error);
				}
			};

			const getAllTransactions = async () => {
				try {
					
					const response = await dishesServices().getAllOreders();
					return response.data;

				} catch (error) {
					console.error('Error fetching transactions data:', error);
				}
			};
			
		const cards = async () => {
		try {
			const response = await dishesServices().getAllCards();
			setCardValidation(response.data);
			const cardDetails = response.data.map(card => [card.card_Id, card.max_Amount]);
			 console.log(cardDetails );
			setCardIds(cardDetails);
		} catch (error) {
			console.error("Error fetching cards:", error);
		}
		};

		const fetchStalls = async () => {
			try {
				const stallResponse = await tableServices().getStalls();
				
				return stallResponse; 
			} catch (error) {
				
				return error; 
			}
		};
		
		const checkStallStatus = async () => {
			try {

				const stallResponse = await fetchStalls();
				if (!stallResponse) {
					console.error('Failed to fetch stalls data.');
					return false;
				}
		
				const stalls = stallResponse.data;
		
				const selectedStall = stalls.find(stall => stall.stallId === stallId);
		
				if (selectedStall) {

					const { tableSt, cardSt } = selectedStall;
		
					if (tableSt === true && cardSt ===false) {
						
						return tableCardPermissions.TableYesCardNo;

					} else if(tableSt === false && cardSt ===false) {

						return tableCardPermissions.TableNoCardNo;

					} else if(cardSt === true) {

						return tableCardPermissions.CardOnly;

					} else{

						return tableCardPermissions.TableYesCardYes;

					}

				} else {

					console.error(`Stall with stallId ${stallId} not found`);
					return false;

				}
		
			} catch (error) {

				console.error('Error processing stall data:', error);
				return false;
				
			}
		};

	  const clearBillData = async () => {
		await setOrderData([]);
		await setcardNo('');
		await setRfidCardNo('');
		await setRemainingCount(0);
		setModalShow(true);
	};

	const SearchOders = async () => {
		try {
			if (cardNo) {
				if (orderData && orderData.length > 0) {

					const result = await Swal.fire({
						icon: 'warning',
						title: 'Existing Order',
						text: 'You have an existing order. Do you want to remove it and continue?',
						showCancelButton: true,
						confirmButtonText: 'Remove',
						cancelButtonText: 'Cancel',
						confirmButtonColor: '#d33',
						cancelButtonColor: '#3085d6',
						allowOutsideClick: false,
					});
	
					if (result.isConfirmed) {
						setOrderData([]);
					} else {
						return;
					}
				}
	
				var foundCard = cardValidation?.find(card => card.kcc_Id === cardNo) || null;
				if (foundCard) {
					setcardNo(foundCard.card_Id);
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: 'Invalid physical card number',
						confirmButtonColor: '#d33',
						allowOutsideClick: false,
					});
				}
			}
		} catch (error) {
			console.error("ERROR", error);
		}
	};
	  useEffect(() => {

		localStorage.setItem('orderData', JSON.stringify(orderData));
	  }, [orderData]);
	
	  useEffect(() => {
		if (rfidCardNo) {
			const foundManagerCard = managerCards?.find(card => card.card_Id === rfidCardNo) || null;
			const foundCard = cardValidation?.find(card => card.card_Id === rfidCardNo) || null;
			
	
			if (foundManagerCard) {
				setRfidManagerDetails(foundManagerCard);
			} else if (foundCard) {
				setcardNo(rfidCardNo);
			} else {
				setcardNo('');
				toast.error("Invalid card");
			}
		}
	}, [rfidCardNo]);
	
	  
	  useEffect(() => {
		if (rfidManagerDetails?.userName) 
		{
			setManagerUsername(rfidManagerDetails?.userName);
			setManagerPassword(rfidManagerDetails?.card_Id);
			setMangerTrue(rfidManagerDetails?.card_Id);
		}
	  }, [rfidManagerDetails]);

	  useEffect(() => {
        if (cardNo && orderData.length > 0 && cardNo !== previousCardNo.current) {
            Swal.fire({
                icon: 'warning',
                title: 'Existing Order',
                text: 'You have an existing order. Do you want to remove it and continue?',
                showCancelButton: true,
                confirmButtonText: 'Remove',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    setOrderData([]);
                    previousCardNo.current = cardNo;
                } else {
                    setcardNo(previousCardNo.current);
                }
            });
        } else {
            previousCardNo.current = cardNo;
        }
    }, [cardNo, orderData]);


	useEffect(() => {
		const ws = new WebSocket('ws://4.240.102.206:3600');
	
		ws.onopen = () => {
		  console.log('Connected to WebSocket server');
		};
	
		ws.onmessage = (event) => {
			const data = JSON.parse(event.data);	
			if (data.unique_key === stallId) {
					setRfidCardNo(data?.decimal_value);
			};
			};
	
		ws.onclose = () => {
		  console.log('WebSocket connection closed');
		};
	
		return () => {
		  ws.close();
		};
	  }, []);


	useEffect(() => {
		 
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
		
		setModal(new Modal(document.getElementById('modalPosItem')));
		fetchDishes();
		fetchTaxes();
		fetchTables();
		cards();
		fetchStalls();
		fetchManagerCards();
		localStorage.setItem('customerScreenStatus',"Order");
		if(localStorage.getItem('stallID') == 8 || localStorage.getItem('stallID') == 7)
			{
				setstall(true);
			}	 
			setRemainingCount(0);
			setRfidCardNo('');
			setcardNo('');
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		}

	}, []);

	  //Table button handling 
		const handleOpenModal = () => {
			setShowModal(true);
		};
	
		const handleCloseModal = () => {
			setShowModal(false); 
		};

		const handleTableChange = (event) => {
			const selectedValue = event.target.value;
			setSelectedTableId(selectedValue);
	};
	
	
	
		const handleDoneClick = () => {
			setLocalTableId(selectedTableId); 
			setShowModal(false);
		};

		const loaderStyle = {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			zIndex: 1000,
		};

		const fetchTotalamount = async () => {
			try {
				var totalAmountSum = await getTransactionsTotal(cardNo);
				if (totalAmountSum === undefined) {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: 'Unable to proceed with the order. Please <span style="color: red; font-weight: bold;">refresh the page</span>.',
						confirmButtonColor: '#d33',
						allowOutsideClick: false,
					});
					return;
				}
				var foundCard = cardValidation?.find(card => card.card_Id === cardNo || card.kcc_id === cardNo ) || null;
				if(foundCard)
					{
						setRemainingCount((foundCard.max_Amount || 0)-totalAmountSum);
					}
					
			} catch (error) {
				console.error('Error fetching transactions:', error);
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: 'Unable to proceed with the order. Please <span style="color: red; font-weight: bold;">refresh the page</span>.',
					confirmButtonColor: '#d33',
					allowOutsideClick: false,
				});
			}
		};

		useEffect(() => {
			if (cardNo && !isNaN(Number(cardNo))) {
				fetchTotalamount();
			}
		}, [cardNo])

		useEffect(() => {
			return () => {
				if (modalInstanceRef.current) {
					try {
						modalInstanceRef.current.dispose();
						modalInstanceRef.current = null;
						
						const backdrop = document.querySelector('.modal-backdrop');
						if (backdrop) {
							backdrop.remove();
						}
					} catch (error) {
						console.error('Error disposing modal:', error);
					}
				}
			};
		}, []);

		const [confirmationVisibility, setConfirmationVisibility] = React.useState(() => {
			// Initialize visibility for all orders as false
			const visibility = {};
			orderData?.forEach((order) => {
			  visibility[order.id] = false; // Initially hidden
			});
			return visibility;
		  });
		  
		  const handleItemNameClick = (orderId) => {
			setConfirmationVisibility((prevState) => ({
			  ...prevState,
			  [orderId]: !confirmationVisibility[orderId], // Ensure section is hidden when clicking item name
			}));
		  };

		  
		  const handleAddOpenItem = (newItem) => {
			if (!cardNo) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Cannot add Menu Items without a card.',
					confirmButtonColor: '#d33',
					allowOutsideClick: false,
				});
				return;
			}
		
			const openItemRecord = tableData?.find((item) => item.subCategory === "OPEN FOOD" || item.subCategory === "OPEN BEVERAGE");
		  
			if (!openItemRecord) {
			  console.error("No OPEN FOOD or OPEN BEVERAGE record found in tableData or tableData is empty");
			  return;
			}
		
			const calculatedTaxes = Taxes?.map((tax) => {
				const taxPercentage = parseFloat(tax.taxRate) || 0;
				const taxValue = (parseFloat(newItem.price) || 0) * (taxPercentage / 100);
				return {
					taxItemId: 0,
					orderItemId: 0,
					acumaticaTaxId: 0,
					taxCode: tax.taxCode,
					taxType: tax.taxType,
					taxPercentage,
					taxValue,
				};
			}) || [];
		
			const taxTotal = calculatedTaxes.reduce((total, tax) => total + tax.taxValue, 0);
			const totalPriceWithTaxes = parseFloat(newItem.price || 0) + taxTotal;
		
			if (totalPriceWithTaxes > remainingCount) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: `The maximum allowed amount for this card exceeds.`,
					confirmButtonColor: '#d33',
					allowOutsideClick: false,
				});
				return;
			}
		
			setOrderData((prevItems) => [
			  ...prevItems,
			  {
				orderItemId: 0,
				transactionID: 0,
				itemName: newItem.foodItemName,
				quantity: newItem.quantity,
				itemPrice: newItem.price,
				taxItems: calculatedTaxes,
				addOns: [],
				addOnTotal: 0,
				menuItemId: openItemRecord.MenuItemId || openItemRecord.menuItemId,
				createdByScreenID: "NEED-CLARIFICATION-FROM-ACU",
				id: uuidv4(),
				stallID: openItemRecord.stallID,
			  },
			]);
		  
			setOpenItems((prevItems) => [...prevItems, newItem]);
		  };
		  
		  
	
	return (
		<>

      <CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
	  <ToastContainer />
	  <div className="modal fade" id="inProgressOrdersModal" 
			tabIndex="-1" 
			aria-labelledby="inProgressOrdersLabel"
			aria-hidden="true"
		 >
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="inProgressOrdersLabel">
							In-Progress Orders for Card {cardNo}
						</h5>
						<button
							type="button"
							className="btn-close"
							onClick={closeOrdersModal}
						></button>
					</div>
					<div className="modal-body">
						{cardInProgressOrders.length > 0 ? (
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>Order ID</th>
											<th>Stall</th>
											<th>Items</th>
											<th>Total Amount</th>
										</tr>
									</thead>
									<tbody>
										{cardInProgressOrders.map((order, index) => (
											<tr key={index}>
												<td>{order.transactionID}</td>
												<td>{(order.stallId || order.stallName)?.toString().replace('WS', '')}</td>
												<td>
													{order.transactionItems?.map((item, idx) => (
														<div key={idx}>
															{item.itemName} x {item.quantity}
														</div>
													))}
												</td>
												<td>RS {order.totalAmount.toFixed(2)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="text-center p-3">
								No in-progress orders found for this card
							</div>
						)}
					</div>
					<div className="modal-footer">
						<button 
							type="button" 
							className="btn btn-secondary"
							onClick={closeOrdersModal}
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
		<div className="h01-100 content-with-header">
			<div className={'pos pos-with-menu pos-with-sidebar ' + ((posMobileSidebarToggled) ? 'pos-mobile-sidebar-toggled' : '')} id="pos">
				<div className="pos-container">
				<div className="pos-header fixed-header">
				<PosHeader />
				</div>
					<div className="pos-menu h02-100">
						<div className="nav-container">
						<PerfectScrollbar className="">
							<ul className="nav nav-tabs">
								{tableData && (
									<>
										<li className="nav-item">
											<a 
												className={'nav-link' + (categoryType === "all" ? ' active' : '')} 
												onClick={(event) => showType(event, "all")} 
												href="#/"
											>
												<i className="fa fa-fw fa-utensils"></i> All Dishes
											</a>
										</li>
										{[
											...new Map(
												tableData.map((category) => [category?.subCategory, category])
											).values(),
											]
											.sort((a, b) => a.subCategory.localeCompare(b.subCategory)) // Sort alphabetically
											.map((category, index) => (
												<li className="nav-item" key={index}>
													<a 
														className={'nav-link' + ((category?.subCategory === categoryType) ? ' active' : '')} 
														onClick={(event) => {
															if (category?.subCategory === "OPEN FOOD" || category?.subCategory === "OPEN BEVERAGE") {
															event.preventDefault();
															handleModalOpen();
															} else {
															showType(event, category?.subCategory);
															}
														}} 
														href="#/"
													>
														{category?.subCategory}
													</a>
												</li>
											))
										}
									</>
								)}
							</ul>
						</PerfectScrollbar>

						<OpenItemModal
							isOpen={isModalOpen}
							onClose={handleModalClose}
							onSubmit={handleAddOpenItem}
						/>

						</div>
					</div>
					<div className="pos-content">
					<div className="pos-content-container">
					{isLoading && (
						<div style={loaderStyle}>
							<div className="spinner-border text-light" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					)}
					{isOrderSubmitting && (
						<div
							style={{
							position: 'fixed',
							top: 0,
							left: 0,
							width: '100vw',
							height: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							zIndex: 1000,
							}}
						>
						<div role="status">
							<span
								style={{
								color: '#F57C00',
								fontSize: '1.5rem',
								fontWeight: 'bold',
								}}
							>
							Submitting order, please wait...
							</span>
						</div>
					</div>
					)}
					 <div className="row gx-4">
						{tableData && tableData.filter(item =>
							item?.subCategory !== "OPEN FOOD" && 
							item?.subCategory !== "OPEN BEVERAGE" &&
							!item.hide
						).length > 0 ? (
							tableData.filter(item =>
								item?.subCategory !== "OPEN FOOD" && 
								item?.subCategory !== "OPEN BEVERAGE" &&
								!item.hide
							).map((item, index) => (
							<div className="col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6 pb-4" key={index}>
								<a 
									href="#" 
									className="pos-product" 
									onClick={(event) => {
										if ((voidData && isVoidData) ? cardData?.cardId : cardNo) {
										showPosItemModal(event, item);
										}
									}}
									>
									<div
										className={`info ${orderData?.some(prevItem => prevItem.id === item.id) ? 'highlight' : ''}`}
									>
										<div className="title" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
										{item?.title}
										</div>
										<div className="price">
										RS {item?.price?.toFixed(2)}
										</div>
									</div>
								</a>
							</div>
							))
						) : (
							!isLoading && <div className="col-12">No records found</div>
						)}
						</div>

				</div>
				</div>
					
					<div className="pos-sidebar" id="pos-sidebar">
						<div className="h-100 d-flex flex-column p-0">
						<div className="pos-sidebar-header">
						<div className="back-btn">
							<button type="button" onClick={dismissMobileSidebar} className="btn">
								<i className="fa fa-chevron-left"></i>
							</button>
						</div>
               
						{(stallStatus === tableCardPermissions.CardOnly) && (
						<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							<input
							type="text"
							style={{ flex: 3, marginRight: '10px', height: '40px' }}
							className="form-control"
							placeholder="Enter RFID Card No."
							value={(voidData && isVoidData) ? cardData?.cardId : cardNo}
							disabled={voidData && isVoidData}
							onChange={(e) => {
								const value = e.target.value;
								setcardNo(value);
							}}
						/>
							<button 
							className="btn btn-primary" 
							style={{ flex: 1, height: '40px', maxWidth: '150px' }}
							onClick={SearchOders}
							>
							Search
							</button>
							<button 
								className="btn btn-info" 
								style={{ flex: 1, 
									height: '40px',  
									maxWidth: '150px',
									marginLeft: '10px',
                                    backgroundColor: '#ff8000',
                                    color: 'white'
								 }}
								onClick={handleOrdersClick}
								disabled={!cardNo || showOrdersModal}
							>
								Orders
							</button>
						</div>
						)}

						</div>
							<PerfectScrollbar className="pos-sidebar-body tab-content h-100">
								<div className="tab-pane fade h-100 show active" id="newOrderTab">
									{orderData && orderData?.length > 0 ? (orderData?.map((order, index) => (
										<div className="pos-order" key={index}>
											<div className="pos-order-product">
												<div className="flex-1">
													<div className="h6 mb-1" onClick={() => handleItemNameClick(order.id)} style={{ cursor: 'pointer' }}>{order?.itemName} [ x {order?.quantity}]</div>
													{/* <div className="small">RS {order?.itemPrice}</div> */}
													<div className="small mb-2">
														{voidData && isVoidData ? (
															<>
																{order?.addOns && order?.addOns.map((addOns, index) => {
																 
																return (
																<div key={index}>
																	- {"addOn"}: {addOns.addOn} - RS {addOns.price}
																</div>
																);
																})}
															</>
															):(
															<>
																{order?.addOns && order?.addOns.map((addOns, index) => {
																 
																return (
																<div key={index}>
																	- {addOns.key}: {addOns.addon} - RS {addOns.price}
																</div>
																);
																})}
															</>
															)
																
															}
													</div>

													{confirmationVisibility[order.id] && (<div className="d-flex">
														<a href="#/" className="btn btn-secondary btn-sm" onClick={(event) => deductQty(event, order.id)}><i className="fa fa-minus"></i></a>
														<input
															type="text"
															className="form-control w-50px form-control-sm mx-2 bg-white bg-opacity-25 text-center no-spinner"
															value={order?.quantity}
															// onChange={(event) => handleQtyChange(event, order.id)}
															min="1"
														/>

														<a href="#/" className="btn btn-secondary btn-sm" onClick={(event) => addQty(event, order.id)}><i className="fa fa-plus"></i></a>
													</div>)}
												</div>
											</div>
											<div className="pos-order-price d-flex flex-column">
											<div>RS { getTotalDishPrice(order)}</div>
											{confirmationVisibility[order.id] && (<div className="text-end mt-auto">
													<button onClick={(event) => toggleConfirmation(event, order.id, true)} className="btn btn-default btn-sm">
													<i className="fa fa-trash"></i>
													</button>
												</div>)}
											</div>
											{order?.confirmation && (
												<div className="pos-order-confirmation text-center d-flex flex-column justify-content-center">
													<div className="mb-1">
														<i className="bi bi-trash fs-36px lh-1"></i>
													</div>
													<div className="mb-2">Remove this item?</div>
													<div>
														<button onClick={(event) => toggleConfirmation(event, order.id, false)} className="btn btn-outline-white btn-sm ms-auto me-2 width-100px">No</button>
														<button onClick={(event) => removeOrder(event, order.id)} className="btn btn-outline-theme btn-sm width-100px">Yes</button>
													</div>
												</div>
											)}
										</div>
									))) : (
										<div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
											<div>
												<div className="mb-3 mt-n5">
													<i className="bi bi-bag text-white text-opacity-50" style={{fontSize: '6em'}}></i>
												</div>
												<h5>No order found</h5>
											</div>
										</div>
									)}
								</div>
						
								<div className="tab-pane fade h-100" id="orderHistoryTab">
									<div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
										<div>
											<div className="mb-3 mt-n5">
												<svg width="6em" height="6em" viewBox="0 0 16 16" className="text-gray-300" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z"/>
													<path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z"/>
												</svg>
											</div>
											<h5>No order history found</h5>
										</div>
									</div>
								</div>
							</PerfectScrollbar>
							
							<div className="pos-sidebar-footer">
							<div style={{ display: stall ? 'none' : 'block' }}>
							<div className="d-flex align-items-center mb-2">
									<div>Remaining Card Amount</div>
									<div className="flex-1 text-end h4 mb-0">{remainingCount.toFixed(2)}</div>
									</div>
								<hr className="opacity-1 my-10px" />
								{/* --------------------------------------- */}
								<div className="d-flex align-items-center mb-2">
								{getSubTotalPrice()}
								{Taxes && Taxes.forEach(tax => getTaxesPrice(tax?.taxType))}
								</div>
								{/* --------------------------------------- */}
								{false && (
								<div className="d-flex align-items-center mb-2">
									<div>Subtotal</div>
									<div className="flex-1 text-end h6 mb-0">RS {getSubTotalPrice()}</div>
								</div>
								)}

								{false && (
								<div className="small mb-2">
									{Taxes && Taxes?.map((tax, index) => (
									<div key={index} className="d-flex justify-content-between">
										<div>{tax?.taxType}: {tax?.taxRate} %</div>
										<div className="flex-1 text-end h6 mb-0">RS {getTaxesPrice(tax?.taxType)}</div>
									</div>
									))}
								</div>
								)}
							</div>
								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Total</div>
									 <div className="flex-1 text-end h4 mb-0">RS {(voidData && isVoidData) ? ((total != 0) ? total : (totalPrice.toFixed(2)) ) : (totalPrice.toFixed(2))}</div>
								</div>
								<div className="mt-3">
									<div className="d-flex">
										{voidData && isVoidData ? (
											<>
											<button
												className="btn btn-default flex-fill w-70px me-10px d-flex align-items-center justify-content-center"
												onClick={openCancelOrderModal}
											>
												<span>
												<i className="fa fa-trash fa-lg my-10px d-block mx-auto"></i>
												<span className="small fw-semibold">
													Void Full Order
												</span>
												</span>
											</button>
											<button
												className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
												onClick={openModal}
												disabled={isEmptyVoidData}
											>
												Submit Order
											</button>
											</>
										) : (
											<>

											<button
												className="btn btn-default w-70px me-10px d-flex align-items-center justify-content-center"
												onClick={clearBillData}
											>
												<span>
												<i className="fa fa-trash fa-lg my-10px d-block"></i>
												<span className="small fw-semibold">Clear</span>
												</span>


											</button>
											
											{(stallStatus === tableCardPermissions.TableYesCardNo) && (
											<button
												className="btn btn-default w-70px me-10px d-flex align-items-center justify-content-center"
												onClick={handleOpenModal}
																										>
												<span>
													<i className="fa fa-table fa-lg my-10px d-block"></i>
													<span className="small fw-semibold">Table</span>
												</span>

											</button>

											)}

												{showModal && (
													
													<div
														style={{
															position: 'fixed',
															top: 0,
															left: 0,
															width: '100%',
															height: '100%',
															backgroundColor: 'rgba(0, 0, 0, 0.5)',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															zIndex: 1000,
														}}
													>
														<div
															style={{
																backgroundColor: 'white',
																padding: '20px',
																borderRadius: '8px',
																textAlign: 'center',
																width: '300px',
															}}
														>
															<p><b>Table Arrangement</b></p>
															<select
																className="form-select mb-3"
																value={selectedTableId} 
																onChange={handleTableChange}
															>
																{GetTables.length > 0
																	? GetTables
																			.filter((table) => table.stallId === stallId) 
																			.map((table) => (
																				<option key={table.tableId} value={table.tableId}>
																					{table.tableId}
																				</option>
																			))
																	: <option>No tables available</option>}
															</select>

															<button className="btn btn-primary" onClick={handleDoneClick}>
																Done
															</button>
															
														</div>
													</div>
												)}

												{/* Future reference */}
											{/* <button className="btn btn-default w-70px me-10px d-flex align-items-center justify-content-center">
												<span>
												<i className="fa fa-receipt fa-fw fa-lg my-10px d-block"></i>
												<span className="small fw-semibold">Bill</span>
												</span>
											</button> */}
											
										
											{(stallStatus === tableCardPermissions.TableNoCardNo) ? (
												<button
												className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
												onClick={handleClick}
											>
												Pay
											</button>
												) : (
													<button
												className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
												onClick={(event) =>{ 
													setIsOrderSubmitting(true); 
													submitOrder(event);
												}}
												disabled={isButtonDisabled}
											>
												Submit Order
											</button>
												)}


											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<a href="#/" className="pos-mobile-sidebar-toggler" onClick={toggleMobileSidebar}>
				<i className="fa fa-shopping-bag"></i>
				<span className="badge">{getOrderTotal()}</span>
			</a>
	
			<div className="modal modal-pos fade" id="modalPosItem">
				<div className="modal-dialog modal-lg">
					<div className="modal-content border-0">
						{modalData && (
							<div>
								<div className="p-0">
									<button data-bs-dismiss="modal" className="btn-close position-absolute top-0 end-0 m-4">&nbsp;</button>
									<div className="modal-pos-product">
										<div className="modal-pos-product-info">
											<div className="fs-4 fw-semibold">{ modalData?.title }</div>
											<div className="text-body text-opacity-50 mb-2">
												{ modalData?.description }
											</div>
											<div className="fs-3 fw-bold mb-3">RS { modalData?.price?.toFixed(2) }</div>
											<div className="d-flex mb-3">
												<button className="btn btn-secondary" onClick={(event) => deductModalQty(event)}><i className="fa fa-minus"></i></button>
												<input type="text" value={modalQuantity} readOnly className="form-control w-50px fw-bold mx-2 text-center" />
												<button className="btn btn-secondary" onClick={(event) => addModalQty(event)}><i className="fa fa-plus"></i></button>
											</div>
											<hr className="mx-n4" />
											{modalData && modalData?.menuItemAddOns && (
												<div className="mb-2">
													<div className="fw-bold">Add On:</div>
													<div className="option-list">
														{modalData?.menuItemAddOns?.map((addon, index) => (
															<div className="option" key={index}>
																<input 
																	type="checkbox" 
																	name="addon" 
																	className="option-input" 
																	onChange={(event) => handleAddonChange(event)} 
																	value={addon?.addOnName} 
																	data-price={addon?.addOnPrice} 
																	id={'addon' + index}
																	checked={modalSelectedAddon.some(selected => selected?.value === addon?.addOnName)} // Ensure that checked state is based on selected addons
																/>
																<label className="option-label" htmlFor={'addon' + index}>
																	<span className="option-text">{addon?.addOnName}</span>
																	<span className="option-price">+RS {addon?.addOnPrice.toFixed(2)}</span>
																</label>
															</div>
														))}
													</div>
												</div>
											)}
											<hr className="mx-n4" />
											<div className="row">
												<div className="col-4">
													<button className="btn btn-default fw-semibold mb-0 d-block py-3 w-100" data-bs-dismiss="modal">Cancel</button>
												</div>
												<div className="col-8">
													<button className="btn btn-theme fw-semibold d-flex justify-content-center align-items-center py-3 m-0 w-100" onClick={(event) => addToCart(event)}>Add to cart <i className="bi bi-plus fa-2x ms-2 my-n3"></i></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="modal fade" id="modalVoidItem"
				tabIndex="-1"
				aria-labelledby="voidOrderLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="voidOrderLabel">
								Confirm Void Order
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body"> 
						<div className="mb-1">
							<label>Reason for Voiding Order:</label>
							<div style={{ display: "flex", alignItems: "center" }}>
							<select
								ref={dropdownRef}
								className="form-control"
								value={voidReason}
								onChange={(e) => setVoidReason(e.target.value)}
								style={{ width: "60%" }}
							>
								<option value="" disabled>
								Select a reason
								</option>
								{voidReasonData?.map((reason) => (
								<option key={reason.reasonId} value={reason.reasonCode}>
									{reason.description}
								</option>
								))}
							</select>
							<div style={{ display: "flex", marginLeft: "10px", gap: "5px" }}>
								<button
								type="button"
								onClick={handleMoveUp}
								className="btn btn-light"
								>
								▲
								</button>
								<button
								type="button"
								onClick={handleMoveDown}
								className="btn btn-light"
								>
								▼
								</button>
							</div>
							</div>
						</div>
							<div className="row mb-3">
								<div className="col">
									<label>Manager Username:</label>
									<input
										type="text"
										className="form-control"
										value={managerUsername}
										onChange={(e) => {
											setManagerUsername(e.target.value);
											setRfidManagerDetails([]);
											setMangerTrue(false);
											setRfidCardNo('')
										}}
									/>
								</div>
								<div className="col">
									<label>Manager Password: </label>
									{mangerTrue && (
										<label style={{ color: 'red', fontWeight: 'bold' }}>RFID</label>
										)}
										<input
										type="password"
										className="form-control"
										value={managerPassword}
										onChange={(e) => {
											setManagerPassword(e.target.value);
											setRfidManagerDetails([]);
											setMangerTrue(false);
											setRfidCardNo('')
										}}
									/>
								</div>
							</div>

							{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
						</div>

						<div className="modal-footer">
						<button
							type="button"
							onClick={fetchVoidReasons}
							className="btn btn-theme"
						>
							Void Reasons
						</button>
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={(event) => {
									setIsOrderSubmitting(true);
									event.preventDefault();
									handleSubmitOrder();
									setRfidManagerDetails([]);
									setMangerTrue(false);
									setRfidCardNo('')
								}} 
								disabled={!managerUsername || !managerPassword || !voidReason}
							>
								Submit Order
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="cancelOrderModal"
				tabIndex="-1"
				aria-labelledby="cancelOrderLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="cancelOrderLabel">
								Confirm Void Full Order
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<p>Are you sure you want to cancel this order?</p>
							<p className="text-danger">
								<strong>
									Once confirmed, this action cannot be reversed.
								</strong>
							</p>
							<div className="mb-3">
							<label>Reason for Voiding Order:</label>
							<div style={{ display: "flex", alignItems: "center" }}>
							<select
								ref={dropdownRef}
								className="form-control"
								value={voidReason}
								onChange={(e) => setVoidReason(e.target.value)}
								style={{ width: "60%" }}
							>
								<option value="" disabled>
								Select a reason
								</option>
								{voidReasonData?.map((reason) => (
								<option key={reason.reasonId} value={reason.reasonCode}>
									{reason.description}
								</option>
								))}
							</select>
							<div style={{ display: "flex", marginLeft: "10px", gap: "5px" }}>
								<button
								type="button"
								onClick={handleMoveUp}
								className="btn btn-light"
								>
								▲
								</button>
								<button
								type="button"
								onClick={handleMoveDown}
								className="btn btn-light"
								>
								▼
								</button>
							</div>
							</div>
						</div>
							<div className="row mb-3">
								<div className="col">
									<label>Manager Username:</label>
									<input
										type="text"
										className="form-control"
										value={managerUsername}
										onChange={(e) => {
											setManagerUsername(e.target.value)
											setRfidManagerDetails([]);
											setMangerTrue(false);
											setRfidCardNo('')
										}}
									/>
								</div>
								<div className="col">
									<label>Manager Password:</label>
									{mangerTrue && (
										<label style={{ color: 'red', fontWeight: 'bold' }}>RFID</label>
										)}
										<input
										type="password"
										className="form-control"
										value={managerPassword}
										onChange={(e) => {
											setManagerPassword(e.target.value);
											setRfidManagerDetails([]);
											setMangerTrue(false);
											setRfidCardNo('')
										}}
									/>
								</div>
							</div>

							{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
						</div>

						<div className="modal-footer">
						<button
							type="button"
							onClick={fetchVoidReasons}
							className="btn btn-theme"
						>
							Void Reasons
						</button>
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={handleCancelOrder}
								disabled={!managerUsername || !managerPassword || !voidReason}
							>
								Confirm Void Full Order
							</button>
						</div>
					</div>
				</div>
			</div>

				
			</div>
		</>
	)
}

export default PosCustomerOrder;
